import packageJson from '../../../../package.json';
import { sub } from '../date/sub';

export const SETTINGS = {
  cacheBreaker: 'filter.cacheBreaker',
  dateFilter: 'filter.date',
  farmFilter: 'filter.farm',
  groupFilter: 'filter.group.1',
  herdFilter: 'filter.herd',
  periodFilter: 'filter.period',
} as const;

export const DASHBOARD_STATUS = {
  widgetCollapsed: 'dashboardState.widgetCollapsed',
} as const;

export const DETAILS_STATUS = {
  columnVisibility: 'detailsState.chart.columnVisibility',
  displayChartTooltip: 'detailsState.chart.displayTooltip',
  widgetCollapsed: 'detailsState.widgetCollapsed',
} as const;

export const SESSION = {
  userId: 'session.userId',
} as const;

const CACHE_VERSION = 'v5';

export const CACHE = {
  animalTasks: ['cache.dashboardWidgets.animalTasks', CACHE_VERSION].join('.'),
  diseases2: ['cache.dashboardWidgets.diseasesWidget', CACHE_VERSION].join('.'),
  coefficient2: ['cache.dashboardWidgets.coefficientWidget', CACHE_VERSION].join('.'),
  fatAndProteinRatio2: ['cache.dashboardWidgets.fatAndProteinRatioWidget', CACHE_VERSION].join('.'),
  health2: ['cache.dashboardWidgets.healthWidget', CACHE_VERSION].join('.'),
  income2: ['cache.dashboardWidgets.incomeWidget', CACHE_VERSION].join('.'),
  milkYield2: ['cache.dashboardWidgets.milkYieldWidget', CACHE_VERSION].join('.'),
  milkCompositionWeighted: ['cache.dashboardWidgets.milkCompositionWeightedWidget', CACHE_VERSION].join('.'),
  reproduction: ['cache.dashboardWidgets.reproductionWidget', CACHE_VERSION].join('.'),
} as const;

export const ANIMAL_GROUP_ALL_ID = '-1';
export const ALL_ANIMAL_GROUPS = -1;
export type AnimalGroups = string[];

export const DEFAULT_ANIMAL_GROUPS: AnimalGroups = [`${ALL_ANIMAL_GROUPS}`];

export const DEFAULT_INTERVAL_COUNT = 7;

export const BACKGROUND_COLOR = '#EDEFF3';

export const DASHBOARD_CHART_ASPECT_RATIO = 16 / 9;

export const MIN_DATE_TO_DISPLAY_USER = sub(new Date(), { years: 2 }); // We agreed to allow today - 2 years go back in time for now.

export const MIN_DATE = '1900-01-01';

/* Use "en dash" https://www.thesaurus.com/e/grammar/how-do-i-use-an-en-dash/
 * to separate date components which might already contain hyphen (ISO / lt format)
 * https://www.figma.com/file/OFCTpcit1fgRMC8LU8veDh?node-id=2005:157363#261249813
 */
export const DATE_RANGE_SEPARATOR = String.fromCharCode(8211);

export const QUERY_PARAMS = {
  redirect: 'redirect',
} as const;

export const BOOKMARK_NOTE_LENGTH = 200;

export const MIN_DIM = 0;
// TODO: should be loaded from server, probably with this: https://app.clickup.com/t/24441705/BHLDEV-151
export const MAX_DIM = 1000;

export const APPLICATION_VERSION_INFO = `v${packageJson.version}`;
export const APPLICATION_NAME = 'BROLIS | HerdLine';
export const APPLICATION_COPYRIGHT_INFO = `${APPLICATION_NAME} ${APPLICATION_VERSION_INFO}`;
export const APPLICATION_DESCRIPTION = 'The BROLIS HerdLine app provides information about the health and productivity of dairy cows at the herd, group, and individual animal levels.';

export const DEFAULT_FARM_TIME_ZONE = 'UTC';

export const AUTH0_CLIENT_ID = 'uRDBOL23guhSdAnV3TDdsNOch5pxC3uq';
